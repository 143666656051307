import React, { Component } from 'react';
import Card from './cardVideo';

class video extends Component {
    render() {
        return (
            <div className="mainPage">
                <section className="heroSection videoHero">
                        <h1>Hi, my name is Dave. <br /> I am a video editor / motion graphics artist.</h1>
                </section>

                <section className="bioSection videoBio">
                <h1 className="bioSection__header">Here's a little about me</h1>
                <div className="stackAndBackground">
                    <div className="background_text">
                        <h3>My Background</h3>
                        <p>Growing up in Hamilton, my high school's TV studio drew me into 
                        video production. This led me to study Media Arts at Sheridan college, where I 
                        developed a taste for post-production. From there, I started my own freelance video 
                        business. Later I moved to Toronto in order to work at Dell Productions, where I was employed for 
                        over 12 years.</p>
                        <p>While I still enjoy video production, I was looking for a new challenge and decided to 
                        take up computer programming. After studying in my spare time, 
                        I completed a course in Object Oriented Programming at Humber College. From there I
                        knew I wanted to turn web development into a career and registered for the full-time web 
                         development program at Brain Station.</p>
                        <p>Currently I am searching for employment where I hope to 
                        contribute my skills while learning new ones.</p>
                    </div>
                    <div className="webTech">
                        <img src="video stack.jpg" alt="my video stack"/>
                    </div>
                </div>
            </section>

            <section className="projects videoBackground">
            <h1 className="projects__header videoDarkText">Here are some of my projects:</h1>
            <div className="projectCards">

                <Card imgUrl="demoReel2.png"
                    projectName="My Demo Reel"
                    projectDesc="Shows samples from some of my video work."
                    projectLink="https://vimeo.com/60419524" />

                <Card imgUrl="benVideo.png"
                    projectName="Ben the Super Hero"
                    projectDesc="Ben loves super hero movies, so we put him in one."
                    projectLink="https://vimeo.com/240505839" />

                <Card imgUrl="garayVideo.png"
                    projectName="The Garays"
                    projectDesc="I recreated the simpsons intro, with the Bar Mitzvah boy"
                    projectLink="https://vimeo.com/67257570" />

                <Card imgUrl="sokolVideo.png"
                    projectName="Secret Agent Sokol"
                    projectDesc="A parody of various spy films like James Bond and Mission: Impossible"
                    projectLink="https://vimeo.com/67242182" />

                <Card imgUrl="snlVideo.png"
                    projectName="Matthew on SNL"
                    projectDesc="I put Matthew in Saturday Night Live for his Bar Mitzvah"
                    projectLink="https://vimeo.com/61542674" />


            </div>
        </section>

            <section className="contactMe videoBackground videoDarkText">
                <h1 className="contactMe__header">Contact Me</h1>
                <div className="contact__Circles">
                    <a href="mailto:davidcourtemanche@hotmail.com"><img src="WDFT_-_David_Courtemanche.jpg" alt="my portrait" className="myPortrait" title="Email Me" /></a>
                    <a href="https://www.linkedin.com/in/david-courtemanche257/"><img src="iconmonstr-linkedin-5.png" alt="linkedIn logo" title="My LinkedIn Profile"/></a>
                    <a href="https://github.com/warlock257"><img src="iconmonstr-github-5.png" alt="github logo" title="My Github Profile"/></a>
                    <a href="https://vimeo.com/user16681480"><img src="iconmonstr-vimeo-5.png" alt="Vimeo logo" title="My Vimeo channel" /></a>
                    <a href="David Courtemanche Resume 2022 A.pdf"><img src="resume.png" alt="my resume" title="View my resume" /></a>
                </div>
            </section>

            </div>
        );
    }
}

export default video;