import React, {useState} from 'react'
import {Modal, ModalBody, ModalHeader, Button, ModalFooter} from 'reactstrap'
import {comicsList, heroesList, miscList} from '../constants/comics'

export default function comics() {
    const [modalOpen, setModalOpen] = useState(false)
    const [currentComic, setCurrentComic] = useState("")

    const openModal = (filename) => {
        setCurrentComic(filename)
        setModalOpen(true)
    }

    return (
        <div className="mainPage greyBg">
            <section className="heroSection artHero">
                <h1 style={{letterSpacing: '3px'}}>Artwork</h1>
            </section>

            <div className="greyBg">
                <h3>Odyssey of the Dragonlords (a D&D campaign)</h3>
                <p>For fun, I thought I'd chronicle some of the best moments from a 
                    TTRPG I have on the go, through web comics.
                </p>

                <div className="comicsMap">
                    {comicsList.map((pic) => {
                        return (
                            <div 
                                className="comic-thumbnail" 
                                key={'comic' + pic.id} 
                                onClick={ () => {openModal(pic.filename)} }>
                                    <img src={pic.filename} alt="comic thumbnail" />
                            </div>
                        )
                    })}
                </div>

                <hr />

                <p>More detailed hero illustrations</p>

                <div className="comicsMap">
                    {heroesList.map((pic) => {
                        return (
                            <div 
                                className="comic-thumbnail" 
                                key={'hero'+pic.id} 
                                onClick={ () => {openModal(pic.filename)} }>
                                    <img src={pic.filename} alt="comic thumbnail" />
                            </div>
                        )
                    })}
                </div>

                <hr />

                <h3>3D</h3>
                <p>3D artwork made in Blender, coming soon...</p>

                <hr />

                <h3>Miscellaneous</h3>
                <p>Other random artwork and drawing exercises.</p>

                <div className="comicsMap">
                    {miscList.map((pic) => {
                        return (
                            <div 
                                className="comic-thumbnail" 
                                key={'misc' + pic.id} 
                                onClick={ () => {openModal(pic.filename)} }>
                                    <img src={pic.filename} alt="comic thumbnail" />
                            </div>
                        )
                    })}
                </div>



                <Modal isOpen={modalOpen} size="lg" className="comicModalBody" centered={true}>
                    <ModalHeader className="comicModalHeader">
                        <Button className="closeButton" onClick={ () => setModalOpen(false)}>Close</Button>
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <img src={currentComic} alt="comic" />
                        </div>
                    </ModalBody>
                    <ModalFooter className="comicModalHeader">
                        <Button className="closeButton" onClick={ () => setModalOpen(false)}>Close</Button>
                    </ModalFooter>
                </Modal>

            </div>
        </div>
    )
}
