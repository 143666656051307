import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";

//import Construction from'./components/construction';
import Navbar from './components/navbar'
import Web from './components/web'
import Video from './components/video'
import Donate from './components/donate'
import Comics from './components/comics'
import './App.css';
import './css/styles.scss';

class App extends Component {
  state={
    page:'web'
  }

  updatePageState = (page)=>{
    this.setState({
      page:page
    })
  }

  render() {
    
    return (
      <div className="App">
      <Router>

      <Navbar updatePageState={this.updatePageState}
              page={this.state.page} />
      <Route render={({location})=>(
          <TransitionGroup>
            <CSSTransition
            key={location.key}
            timeout={1000}
            classNames='fade'>

                <Switch location={location} >
                  <Route exact path="/" label="web" render={(props) =>{
                    return <Web {...props} page={this.state.page}/>  
                  }}/>
                  <Route path="/video" label="video" render={(props) =>{
                    return <Video {...props} page={this.state.page} />  
                  }}/>
                  <Route path="/donate" label="donate" render={(props) =>{
                    return <Donate {...props} page={this.state.page} />  
                  }}/>
                  <Route path="/art" label="art" render={(props) =>{
                    return <Comics {...props} page={this.state.page} />  
                  }}/>
                </Switch>

            </CSSTransition>
          </TransitionGroup>
      )} />

        </Router>
      </div>
    );
  }
}

export default App;
