export const comicsList = [
    {
        id: 1,
        filename: "https://firebasestorage.googleapis.com/v0/b/portfolio-e1d16.appspot.com/o/comics%2Fweekend%20at%20Anoras.jpg?alt=media&token=eee7b908-bcab-4831-8523-af9878f47efa",
        name: "Weekend at Anora's"
    },
    {
        id: 2,
        filename: "https://firebasestorage.googleapis.com/v0/b/portfolio-e1d16.appspot.com/o/comics%2FSo%20Scary.jpg?alt=media&token=0b991bc1-a1ec-4e3e-bd94-53b7103a5f1c",
        name: "So Scary"
    },
    {
        id: 3,
        filename: "https://firebasestorage.googleapis.com/v0/b/portfolio-e1d16.appspot.com/o/comics%2FHerkus%20the%20Adorable.jpg?alt=media&token=511df09b-9d36-4e8a-9223-03c021b19bcc",
        name: "Herkus the Adorable"
    },
    {
        id: 4,
        filename: "https://firebasestorage.googleapis.com/v0/b/portfolio-e1d16.appspot.com/o/comics%2FMeet%20Calliope.jpg?alt=media&token=d82db320-6c2b-48e3-81ab-e140d0258c87",
        name: "Meet Calliope"
    },
    {
        id: 5,
        filename: "https://firebasestorage.googleapis.com/v0/b/portfolio-e1d16.appspot.com/o/comics%2FLanguage%20barrier.jpg?alt=media&token=08235c25-6046-49df-a7b6-724bb8b83453",
        name: "Language Barrier"
    },
    {
        id: 6,
        filename: "https://firebasestorage.googleapis.com/v0/b/portfolio-e1d16.appspot.com/o/comics%2FHow%20to%20properly%20fight%20harpies.jpg?alt=media&token=1747a898-cce6-4339-b2ae-a77516ff7498",
        name: "How to properly fight harpies"
    },
    {
        id: 7,
        filename: "https://firebasestorage.googleapis.com/v0/b/portfolio-e1d16.appspot.com/o/comics%2Ffronteir%20psychiatry.jpg?alt=media&token=1d928eb4-69e4-4705-9dcc-6047716f5176",
        name: "frontier psychiatry"
    },
]

export const heroesList = [
    {
        id: 1,
        filename: "https://firebasestorage.googleapis.com/v0/b/portfolio-e1d16.appspot.com/o/heroes%2FAsteria%20hero.jpg?alt=media&token=2d223327-49b1-419d-be7d-d20bace11713",
        name: "Asteria Hero"
    },
    {
        id: 2,
        filename: "https://firebasestorage.googleapis.com/v0/b/portfolio-e1d16.appspot.com/o/heroes%2FAsteria%20profile.jpg?alt=media&token=2c9a144c-b7f8-4a79-bc54-766259172ae5",
        name: "Asteria Profile"
    },
    {
        id: 3,
        filename: "https://firebasestorage.googleapis.com/v0/b/portfolio-e1d16.appspot.com/o/heroes%2FCalliope%20Hero.jpg?alt=media&token=71d1c712-2781-4d43-ab84-eb9d32285a47",
        name: "Calliope Hero"
    },
]

export const miscList = [
    {
        id: 1,
        filename: "https://firebasestorage.googleapis.com/v0/b/portfolio-e1d16.appspot.com/o/Misc%2FElf%20Princess.jpg?alt=media&token=9bc3a9f0-f661-4c7d-91f0-147714de0000",
        name: "Elf Princess"
    },
    {
        id: 4,
        filename: "https://firebasestorage.googleapis.com/v0/b/portfolio-e1d16.appspot.com/o/heroes%2FSuccubus.jpg?alt=media&token=e503a79b-11cc-4b58-bb73-b8e1a35d4943",
        name: "Succubus Tutorial"
    },
]