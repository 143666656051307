import React, { Component } from 'react';

class Card extends Component {
    render() {
        const CtaLink = this.props.ctaLink ?  this.props.ctaLink : 'View on Github';

        return (
            <div className="card">
                <img src={this.props.imgUrl} alt="project screenshot"/>
                <div className="card__text">
                    <h3>{this.props.projectName}</h3>
                    <p>{this.props.projectDesc}</p>
                    <a href={this.props.projectLink} target="_blank" rel="noopener noreferrer" className="projectLink">{CtaLink}</a>
                </div>
            </div>
        );
    }
}

export default Card;