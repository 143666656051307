import React, { Component } from 'react';
import Card from './card';

class web extends Component {
    render() {
        return (
            <div className="mainPage">
                <section className="heroSection webHero">
                    <h1>Hi, my name is Dave. <br /> I am a full stack web developer.</h1>
                </section>

                <section className="bioSection webBio">
                    <h1 className="bioSection__header">Here's a little about me</h1>
                    <div className="stackAndBackground">
                        <div className="background_text">
                            <h3>My Background</h3>
                            <p>Growing up in Hamilton, my high school's TV studio drew me into 
                            video production. This led me to study Media Arts at Sheridan college, where I 
                            developed a taste for post-production. From there, I started my own freelance video 
                            business. Later I moved to Toronto in order to work at Dell Productions, where I was employed for 
                            over 12 years.</p>
                            <p>While I still enjoy video production, I was looking for a new challenge and decided to take up computer 
                            programming. After studying in my spare time, 
                            I completed a course in Object Oriented Programming at Humber College. From there I
                            knew I wanted to turn web development into a career and registered for the full-time web 
                             development program at Brain Station.</p>
                            <p>Currently I am searching for employment where I hope to contribute my skills while learning new ones.</p>
                        </div>
                        <div className="webTech">
                            <img src="tech stack.jpg" alt="my tech stack"/>
                        </div>
                    </div>
                </section>

                <section className="projects webBackground">
                    <h1 className="projects__header webLightText">Here are some of my projects:</h1>
                    <div className="projectCards">

                        <Card imgUrl="kaitongo_thumbnail.JPG"
                            projectName="Kaitongo"
                            projectDesc="A web app for lawyers to get the news, see upcoming events, and much more. Built in React JS."
                            projectLink="https://kaitongo.com/"
                            ctaLink="Kaitongo's homepage" />

                        <Card imgUrl="roofmax_thumb.jpg"
                            projectName="Roofmax"
                            projectDesc="A web app for roofing companies to prepare quotes, and clients to review and pay for them. Built with React and Loopback JS"
                            projectLink="https://elecsoft.ca/"
                            ctaLink="Elecsoft's homepage" />

                        <Card imgUrl="netwila_thumb.JPG"
                            projectName="Netwila"
                            projectDesc="A shipping logistics platform on both the web (React JS) and mobile (React Native)"
                            projectLink="https://netwila.com/"
                            ctaLink="Netwila's homepage" />

                        <Card imgUrl="dell_photo_screenshot.png"
                            projectName="Dell Productions Mobile App"
                            projectDesc="A React Native app for iOS and Android that sorts and categorizes images on your phone. Images are them uploaded to Firebase, for use in video production."
                            projectLink="https://play.google.com/store/apps/details?id=com.dellphotomobile"
                            ctaLink="View on Google Play" />

                        <Card imgUrl="feature_graphic.jpg"
                            projectName="TV Tracker Two"
                            projectDesc="A React Native app where you can keep track of all the TV shows you'd like to watch. It can also be used for a quick TV show lookup, with full season breakdowns. It has published onto the Google Play Store."
                            projectLink="https://play.google.com/store/apps/details?id=com.tvtrackertwo"
                            ctaLink="View on Google Play" />

                        <Card imgUrl="7thumbnail.jpg"
                            projectName="7shifts Web app"
                            projectDesc="A SaaS web app for restaurant scheduling, payroll, and labor management. It uses React, Redux, and PHP"
                            projectLink="https://www.7shifts.com/"
                            ctaLink="7shifts home page " />

                        <Card imgUrl="photo-screencap.png"
                            projectName="Photo Organizer"
                            projectDesc="A web app for sorting photos into categories, and ordering them within each category."
                            projectLink="https://github.com/warlock257/Photo-React" />

                        <Card imgUrl="hackathon-cap.png"
                            projectName="HERcules"
                            projectDesc="Winner of BrainStation's winter 2019 hackathon, along with designers and data scientists, we created a web site within 7 hours, that would connect young female athletes, with female Olympic Athletes"
                            projectLink="https://github.com/warlock257/hackathon-bs-19" />

                        <Card imgUrl="movieVault.JPG"
                            projectName="My Movie Vault"
                            projectDesc="A mobile web app to keep track of a movie collection, across digital services and physical discs"
                            projectLink="https://github.com/warlock257/movie-vault" />
                    </div>
                </section>

                <section className="quotes-section">
                    <h1>Testimonials</h1>
                    <div className="quotes-box">

                        <div className="quote-text">
                            <img src="quote-66.png" alt="opening quote" className="opening-quote"/>
                            <p className="quote-body">As Dave's Educator at Brainstation, I watched Dave work 
                            through material and solve genuinely hard problems, 
                            both in and out of the course, often with little to no 
                            assistance. Dave has shown an affinity for development, 
                            and self-taught learning.
                            Dave comes highly recommended by my self.
                            <br/><br/>
                            -Edwin Jackson / Educator at Brainstation</p>
                            <img src="quote-99.png" alt="opening quote" className="closing-quote"/>
                        </div>

                        <div className="quote-text">
                            <img src="quote-66.png" alt="opening quote" className="opening-quote"/>
                            <p className="quote-body">David was always polite, 
                            kind, and straightforward to work with. 
                            He was a talented developer with a 
                            keen eye for CSS.
                            <br/><br/>
                            -Henry / Product Manager at 7shifts</p>
                            <img src="quote-99.png" alt="opening quote" className="closing-quote"/>
                        </div>

                        <div className="quote-text">
                            <img src="quote-66.png" alt="opening quote" className="opening-quote"/>
                            <p className="quote-body">Dave, thanks for your hard work and commitment
                            <br/><br/>
                            -Sumathi / CEO, Kaitongo Inc.</p>
                            <img src="quote-99.png" alt="opening quote" className="closing-quote"/>
                        </div>

                        <div className="quote-text">
                            <img src="quote-66.png" alt="opening quote" className="opening-quote"/>
                            <p className="quote-body">
                                David has a great work ethic with a self-starter mindset.  
                                He is not afraid of taking on new challenges and taking the time to work through 
                                options to find the right solutions.  David is evidently passionate about his 
                                work and would make a great member of any web dev team.
                            <br/><br/>
                            -Nayan Patel / Vice President, Operations, Elecsoft</p>
                            <img src="quote-99.png" alt="opening quote" className="closing-quote"/>
                        </div>
                    </div>
                </section>

                <section className="contactMe webBackground webLightText">
                    <h1 className="contactMe__header">Contact Me</h1>
                    <div className="contact__Circles">
                        <a href="mailto:davidcourtemanche@hotmail.com"><img src="WDFT_-_David_Courtemanche.jpg" alt="my portrait" className="myPortrait" title="Email Me" /></a>
                        <a href="https://www.linkedin.com/in/david-courtemanche257/"><img src="iconmonstr-linkedin-5.png" alt="linkedIn logo" title="My LinkedIn Profile"/></a>
                        <a href="https://github.com/warlock257"><img src="iconmonstr-github-5.png" alt="github logo" title="My Github Profile"/></a>
                        <a href="https://vimeo.com/user16681480"><img src="iconmonstr-vimeo-5.png" alt="Vimeo logo" title="My Vimeo channel" /></a>
                        <a href="David Courtemanche Resume 2022 A.pdf"><img src="resume.png" alt="my resume" title="View my resume" /></a>
                    </div>
                </section>
            </div>
        );
    }
}

export default web;
