import React, { Component } from 'react';
import {Link } from 'react-router-dom';

class navbar extends Component {
    
    render() {
        let WebUnderline = "activeUnderline"
        let videoUnderline = "inactiveUnderline"
        let comicsUnderline = "inactiveUnderline"
        if(this.props.page === "web"){
            WebUnderline = "activeUnderline"
            videoUnderline = "inactiveUnderline"
            comicsUnderline = "inactiveUnderline"
        } else if (this.props.page === "video"){
            WebUnderline = "inactiveUnderline"
            videoUnderline = "activeUnderline"
            comicsUnderline = "inactiveUnderline"
        } else if (this.props.page === "art") {
            WebUnderline = "inactiveUnderline"
            videoUnderline = "inactiveUnderline"
            comicsUnderline = "activeUnderline"
        }

        return (
            <div className="navbar">
                <div className="myName">
                    <h3>David Courtemanche</h3>
                </div>
                <div className="navbar__links">
                    <Link to='/' className="navbar__webLink" id={WebUnderline} onClick={()=>{this.props.updatePageState("web")}}>WEB</Link>
                    <Link to='/video' className="navbar__webLink" id={videoUnderline} onClick={()=>{this.props.updatePageState("video")}}>VIDEO</Link>
                    <Link to='/art' className="navbar__VideoLink" id={comicsUnderline} onClick={()=>{this.props.updatePageState("comics")}}>ARTWORK</Link>
                </div>
                <div className="navbar__logos">
                    <a href="mailto:davidcourtemanche@hotmail.com"><img src="email icon.png" alt="email logo"/></a>
                    <a href="https://www.linkedin.com/in/david-courtemanche257/"><img src="iconmonstr-linkedin-5.png" alt="linkedIn logo"/></a>
                    <a href="https://github.com/warlock257"><img src="iconmonstr-github-5.png" alt="github logo"/></a>
                    <a href="https://vimeo.com/user16681480"><img src="iconmonstr-vimeo-5.png" alt="Vimeo logo"/></a>
                    <a href="David Courtemanche Resume 2022 A.pdf"><img src="resume.png" alt="my resume"/></a>
                </div>
            </div>
        );
    }
}

export default navbar;